import React from 'react'
import Layout from '../layouts'
import ContactForm from '../components/contact'
import Cont from '../images/parallax-bg.jpg'

const AboutPage = () => (
	<Layout head={{ title: 'Contact Us' }}>
		<div className="contact">
			<div
				id="hero-section"
				className="bg-cover"
				style={{ background: `url("${Cont}")` }}
			>
				<div className="container">
					<div className="slim-content">
						<h1 className="text-red">Contact Us</h1>
					</div>
				</div>
			</div>
			<div className="container py-5 mt-5">
				<div className="contact-section">
					<div className="container">
						<div className="contact-section-form slim-content">
							<ContactForm />
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default AboutPage
